import React from 'react';
import { AnimationOnScroll } from 'react-animation-on-scroll';

const GuideToInvoiceFinancingBanner = () => {
  return (
    <div
      className="banner banner-style-7"
      style={{
        backgroundImage: "url('/images/guide-to-invoice-financing/bg.jpg')",
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-7 col-xl-7">
            <div className="banner-content -welcome">
              <AnimationOnScroll animateIn="fadeInUp" animateOnce={true} delay={100}>
                <h1 className="title">Guide to Invoice Financing</h1>
                <h3 className="subtitle">
                  Transform your invoices into immediate cash to <span>grow your business</span>
                </h3>
                <p className="desc">
                  Small businesses often struggle with cash flow when their customers take too long
                  to pay their invoices, which can negatively impact their ability to meet their own
                  financial obligations, pay their employees, and grow their business. Learn how
                  invoice financing can help small businesses alleviate the burden of long payment
                  terms.
                </p>
              </AnimationOnScroll>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GuideToInvoiceFinancingBanner;
