import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import libphonenumber from 'google-libphonenumber';
import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.css';
import 'rc-slider/assets/index.css';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import GuideToInvoiceFinancingBanner from '../components/banner/GuideToInvoiceFinancing';
import Footer from '../components/footer/Footer';
import Header from '../components/header/Header';
import Layout from '../components/layout';
import Seo from '../components/seo';

const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();

interface IFormInputs {
  email?: string;
  first_name?: string;
  last_name?: string;
  company?: string;
  mobile_number?: string;
}

declare let grecaptcha: any;

const GuideToInvoiceFinancing = () => {
  const [phone, setPhone] = useState<intlTelInput.Plugin>();
  const [show, setShow] = useState(false);
  const [url, setUrl] = useState();

  Yup.addMethod(Yup.string, 'phone', function (message) {
    return this.test({
      name: 'phone',
      exclusive: true,
      message: message || 'must be a phone number',
      test: async function (value) {
        if (!phone || !value) {
          return false;
        }
        try {
          const area = phone.getSelectedCountryData().iso2.toUpperCase();
          const number = phoneUtil.parse(value, area);
          return phoneUtil.isValidNumber(number);
        } catch (error) {}
        return false;
      },
    });
  });

  const schema = Yup.object().shape({
    company: Yup.string().required('Required'),
    first_name: Yup.string().required('Required'),
    last_name: Yup.string().required('Required'),
    email: Yup.string().email('Invalid email address').required('Required'),
    mobile_number: Yup.string().required('Required').phone('The phone number is invalid.'),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInputs>({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const mobileInput = document.querySelector('#mobile');
    if (mobileInput) {
      const phoneState = intlTelInput(mobileInput, {
        initialCountry: 'auto',
        geoIpLookup: (callback) => {
          axios
            .get<
              undefined,
              {
                data: {
                  country_code: string;
                };
              }
            >('https://ipapi.co/json/', {
              responseType: 'json',
            })
            .then((resp) => {
              if (resp && resp.data && resp.data.country_code) {
                callback(resp.data.country_code.toLowerCase());
              } else callback('sg');
            });
        },
        preferredCountries: ['sg', 'cn', 'hk', 'us'],
      });
      setPhone(phoneState);
    }
  }, []);

  const onSubmit = (data: IFormInputs) => {
    if (!phone) {
      return;
    }

    const area = phone.getSelectedCountryData().dialCode;

    grecaptcha.ready(async () => {
      try {
        const token = await grecaptcha.execute('6Le4KpEaAAAAACJw4liW38ANFo2whC6FjqdWhDdX', {
          action: 'submit',
        });
        const result = await axios.post(
          `${process.env.API_URL}/api/report-downloads`,
          {
            ...data,
            token,
            mobile: `+${area}${data.mobile_number}`,
            year: 'guide',
          },
          {
            responseType: 'json',
          },
        );

        if (result && result.data && result.data.status) {
          setShow(true);
          // 开始下载
          if (result.data.data.url) {
            setUrl(result.data.data.url);
            window.open(result.data.data.url);
          }
        }
      } catch (error) {}

      // 失败弹窗
    });
  };

  return (
    <Layout>
      <Seo
        title="Guide to Invoice Financing | banco"
        description="Get our free guide to invoice financing and discover how it can help your small business overcome cash flow challenges and alleviate the stress of long payment terms. "
      />
      <main className="main-wrapper">
        <Header />
        <GuideToInvoiceFinancingBanner />

        <div
          className="section"
          style={{
            zIndex: 2,
          }}
        >
          <div
            className="container"
            style={{
              position: 'relative',
            }}
          >
            <div className="welcome-wrap">
              <div className="receive-offer-contact looking-ahead-2023">
                <div className="contact-form">
                  <h2>
                    Complete the form to <br />
                    download the report
                  </h2>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    {/* <label htmlFor="company">Company name</label> */}
                    <input
                      {...register('company')}
                      id="company"
                      name="company"
                      placeholder="Enter your company name"
                    />
                    <p>{errors.company?.message}</p>
                    {/* <label htmlFor="email">Email address</label> */}
                    <input
                      {...register('email')}
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Enter your email address"
                    />
                    <p>{errors.email?.message}</p>
                    {/* <label htmlFor="mobile">Mobile number</label> */}
                    <input
                      {...register('mobile_number')}
                      id="mobile"
                      name="mobile_number"
                      placeholder="Mobile phone"
                    />
                    <p>{errors.mobile_number?.message}</p>
                    {/* <label htmlFor="first_name">First name</label> */}
                    <input
                      {...register('first_name')}
                      id="firstname"
                      // name="full_name"
                      placeholder="Enter your first name"
                    />
                    <p>{errors.first_name?.message}</p>
                    {/* <label htmlFor="last_name">Last name</label> */}
                    <input
                      {...register('last_name')}
                      id="lastname"
                      // name="full_name"
                      placeholder="Enter your last name"
                    />
                    <p>{errors.last_name?.message}</p>
                    <div className="btn-wrap">
                      <button type="submit" className="btn btn-primary">
                        Download report
                      </button>
                    </div>
                  </form>
                </div>
              </div>

              <section className="section section-padding-3 section-guide-to-invoice-financing">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12 col-lg-6 col-xl-6">
                      <img
                        className="light-mode"
                        src="/images/guide-to-invoice-financing/laptop.png"
                      />
                    </div>
                    <div
                      className="col-md-12 col-lg-6 col-xl-6"
                      style={{
                        paddingTop: 40,
                      }}
                    >
                      <p className="-breadcrumb">
                        Your definitive guide to <br /> adopting invoice financing for <br /> your
                        business
                      </p>
                      <p className="-title">
                        Get our free guide to invoice financing and discover how it can help your
                        small business overcome cash flow challenges and alleviate the stress of
                        long payment terms. Learn how to access the funds you need to keep your
                        business running smoothly
                      </p>
                    </div>
                  </div>
                </div>
              </section>

              <section className="section section-padding-3 section-guide-to-invoice-financing-highlights">
                <p className="-breadcrumb">Report Highlights</p>
                <div className="row content-2">
                  <div className="col-xl-3 col-lg-6">
                    <div className="item">
                      <div>
                        <img
                          className="light-mode"
                          src="/images/guide-to-invoice-financing/understand.svg"
                        />
                      </div>
                      <div>
                        <p className="-title">
                          Understand what invoice financing is about, and how it works
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-6">
                    <div className="item">
                      <div>
                        <img
                          className="light-mode"
                          src="/images/guide-to-invoice-financing/benefits.svg"
                        />
                      </div>
                      <div>
                        <p className="-title">
                          Benefits of invoice financing versus traditional financing
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-6">
                    <div className="item">
                      <div>
                        <img
                          className="light-mode"
                          src="/images/guide-to-invoice-financing/learn.svg"
                        />
                      </div>
                      <div>
                        <p className="-title">
                          Learn about InvoicePaid+, banco online platform to manage all your invoice
                          financing needs
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-6">
                    <div className="item">
                      <div>
                        <img
                          className="light-mode"
                          src="/images/guide-to-invoice-financing/cost.svg"
                        />
                      </div>
                      <div>
                        <p className="-title">
                          Eligibility and costs of using invoice financing services
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>

        <Modal className="contact-modal download-modal" show={show} onHide={() => setShow(false)}>
          <Modal.Header closeButton>
            <button onClick={() => setShow(false)}>
              <img src="/images/close-img.png" alt="" />
            </button>
          </Modal.Header>
          <Modal.Body>
            <img className="light-mode" src="/images/lookingahead2023/download.svg" />
            <h2>Download successful!</h2>
            <p>
              Your report has been downloaded successfully. If it doesn't, please click the link
              below to <a href={url}>download immediately</a>
            </p>
          </Modal.Body>
        </Modal>

        <Footer />
      </main>
    </Layout>
  );
};

export default GuideToInvoiceFinancing;
